import { createContext, useContext } from "react";
import { route } from "ziggy-js";

export const RouteContext = createContext<typeof route | null>(null);

/**
 * useRoute hook provides access to the Ziggy route function within a React component.
 * It requires the RouteContext to be set up in a higher component.
 * @throws If the route function is not provided in the context.
 */
export function useRoute(): typeof route {
    const fn = useContext(RouteContext);

    if (!fn) {
        throw new Error("Route function must be provided");
    }

    return fn;
}
